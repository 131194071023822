<template>
  <div>
    <div :style="getElementStyle">
      <div v-if="!isDefalutPos">
        <div style="display: flex; align-items: center">
          <label for="text">{{
            data.label ? data.label : data.input_type
          }}</label>
          <span
            v-if="data.validations.required"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span v-if="data.description">
            <i class="el-icon-info" :title="data.description" />
          </span>
          <input
            :style="{ 'text-align': inputTextAlignment, ...getStyle }"
            :placeholder="data.placeholder"
            v-model="data.function_type"
            :suffix-icon="data.properties.el_icon"
            class="AFV"
          />
          <div>
            <span
              v-if="isActive || isDataTableField"
              class="setting-icon"
              @click="openSettings"
            >
              <i class="el-icon-s-tools" />
            </span>
          </div>
        </div>
      </div>
      <el-row align="middle" v-else>
        <el-col
          :span="showLabel && !isDefalutPosq ? 8 : data.description ? 8 : 24"
          :style="computedStyles"
        >
          <label for="text">{{
            data.label ? data.label : data.input_type
          }}</label>
          <i
            class="el-icon-copy-document"
            v-if="!isDataTableField"
            @click="copyDocument"
          ></i>
          <span
            v-if="data.validations.required"
            style="color: red; font-weight: bold"
          >
            *
          </span>
        </el-col>
        <el-col :span="data.description ? 4 : 0">
          <span v-if="data.description">
            <i class="el-icon-info" :title="data.description" />
          </span>
        </el-col>
        <el-col :span="4">
          <span
            v-if="isActive || isDataTableField"
            class="setting-icon"
            @click="openSettings"
          >
            <i class="el-icon-s-tools" />
          </span>
        </el-col>
        <el-col :span="showLabel && isDefalutPosq ? 24 : 12">
          <input
            :style="{ 'text-align': inputTextAlignment, ...getStyle }"
            :placeholder="data.placeholder"
            v-model="data.function_type"
            :suffix-icon="data.properties.el_icon"
            class="AFV"
          />
        </el-col>
      </el-row>
      <!-- <div>
      {{data.label ? data.label : data.input_type}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>
    </div>
    <div>
      <el-input :style="getStyle" :placeholder="data.placeholder" v-model="data.function_type"></el-input>
    </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AggregateFunctionView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField"],
  mounted() {
    if (this.data?.styles && this.data?.styles?.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if(this.data.styles.labelStyle == 'right'){
      //   this.isDefalutPos = false;
      // }
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    inputTextAlignment: {
      get() {
        return this.data.styles && this.data.styles.inputTextAlignments
          ? this.data.styles.inputTextAlignments
          : "left"; // Default value if not provided
      },
      set(value) {
        if (this.data.styles) {
          this.data.styles.inputTextAlignments = value;
        } else {
          this.$set(this.data, "styles", { inputTextAlignments: value });
        }
      },
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
    // tharuni start
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    // tharuni end
    isDefalutPosq() {
      if (this.data?.styles?.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },

  data() {
    return {
      validations: [],
      showLabel: true,
      isDefalutPos: true,
    };
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss" scoped>
.AFV {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: inherit;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  &:hover {
    border-color: #409eff; /* Change the border color on hover */
  }
  &:focus {
    border-color: #409eff; /* Change the border color when focused */
  }
  &::placeholder {
    color: #ccc; /* Use your desired lighter gray color here */
  }
}
</style>